import getData from "@/__main__/get-data.mjs";
import lolFetchLoadingScreenDataRefs from "@/game-lol/fetches/lol-fetch-loading-screen-data.refs.mjs";
import LoLChampionStatsWithMatchups from "@/game-lol/models/lol-champion-stats-matchups.mjs";
import { getChampionStats } from "@/game-lol/utils/api.mjs";

function fetchData(players = []) {
  const requiredPromises = [
    ...players.map((player) => {
      const { championId, role } = player;
      const params = role ? { championId, role } : { championId };
      return getData(
        getChampionStats(championId, params, true),
        LoLChampionStatsWithMatchups,
        ["lol", "overlay", "championStats", championId],
      );
    }),
    ...lolFetchLoadingScreenDataRefs.fetchFunctions.map((fn) => fn()),
  ];

  return Promise.all(requiredPromises);
}

export default fetchData;
